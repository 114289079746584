.home {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100vh;
    padding: 48px;
    box-sizing: border-box;
    background-color: #1c1e22;
    color: white;
}

.header {
    display: flex;
    color: white;
    align-items: center;
    margin-bottom: 0px;
    margin-right: 1650px;
}

.zetachain-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.connect-button {
    font-size: 15px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: orange;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    position: absolute;
    top: 33px;
    right: 110px;
    transition: background-color 0.3s ease;
}

.connect-button:hover {
    background-color: darkorange;
}

.content-container {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .home {
        align-items: center; /* Center content on smaller screens */
        padding: 16px; /* Adjust padding for smaller screens */
    }

    .header {
        margin-right: 16px; /* Adjust margin for smaller screens */
    }

    .connect-button {
        top: 0px;
        right: 0px;
        position: relative; /* Change to fixed for better mobile experience */
    }
}
